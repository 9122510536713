<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="supportCaseData === undefined">
      <h4 class="alert-heading">Error fetching supportcase data</h4>
      <div class="alert-body">
        No supportcase found with this supportcase id. Check
        <b-link class="alert-link" :to="{ name: 'apps-support-cases-list' }">
          Supportcase List
        </b-link>
        for other supportcases.
      </div>
    </b-alert>
    <b-breadcrumb class="pl-0 pb-1">
      <b-breadcrumb-item :to="{ name: 'apps-support-cases-list' }">
        Tickets
      </b-breadcrumb-item>
      <b-breadcrumb-item active> Edit Ticket </b-breadcrumb-item>
    </b-breadcrumb>
    <support-case-edit-tab-account
      v-if="supportCaseData"
      :support-case-data="supportCaseData"
      :priority="priority"
      class="pt-75"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import { BAlert, BLink, BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import SupportCaseEditTabAccount from "./SupportCasesEditTabAccount.vue";
import supportCasesStoreModule from "../supportCasesStoreModule";

export default {
  components: {
    BAlert,
    BLink,

    SupportCaseEditTabAccount,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  methods: {
    refetchData() {
      this.fetchingSupportCases();
    },
  },
  setup() {
    const supportCaseData = ref(null);
    const priority = ref({});
    let noOptionMessage = ref("Sorry, data is loading");
    let noDataMessage = ref("Sorry, data is not avaialble");

    const SUPPORT_CASE_APP_STORE_MODULE_NAME = "app-support-case";

    // Register module
    if (!store.hasModule(SUPPORT_CASE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SUPPORT_CASE_APP_STORE_MODULE_NAME,
        supportCasesStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPORT_CASE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SUPPORT_CASE_APP_STORE_MODULE_NAME);
    });
    const fetchingSupportCases = () => {
      store.commit("appConfig/SPINNER_CONFIG", true);
      store
        .dispatch("app-support-case/fetchSupportCase", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          supportCaseData.value = response.data;
          supportCaseData.value.detailedComments.sort(function (a, b) {
            return new Date(b.created) - new Date(a.created);
          });
          if (supportCaseData.value.description.renderedValue) {
            const html = supportCaseData.value.description.renderedValue.html;
            let content = html
              .toString()
              .replaceAll("\t", "")
              .replaceAll("\n", "")
              .split("\r\n");
            supportCaseData.value.description.value = content[0];
          }

          store.commit("appConfig/SPINNER_CONFIG", false);
        })
        .catch((error) => {
          store.commit("appConfig/SPINNER_CONFIG", false);
          if (error.response.status === 404) {
            supportCaseData.value = undefined;
          }
        });
    };
    store.commit("appConfig/SPINNER_CONFIG", true);

    store
      .dispatch("app-support-case/fetchSupportCase", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        supportCaseData.value = response.data;
        supportCaseData.value.detailedComments.sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        });
        if (supportCaseData.value.description.renderedValue) {
          const html = supportCaseData.value.description.renderedValue.html;
          let content = html
            .toString()
            .replaceAll("\t", "")
            .replaceAll("\n", "")
            .split("\r\n");
          supportCaseData.value.description.value = content[0];
        }
        store.commit("appConfig/SPINNER_CONFIG", false);
      })
      .catch((error) => {
        console.log(error, "error");
        store.commit("appConfig/SPINNER_CONFIG", false);

        if (error.response.status === 404) {
          supportCaseData.value = undefined;
        }
      });

    store
      .dispatch("app-support-case/fetchPriority")
      .then((response) => {
        const priorityObject = {
          response: response.data,
          message: response.data.length == 0 ? noDataMessage : noOptionMessage,
        };
        priority.value = priorityObject;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching priority",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return {
      supportCaseData,
      fetchingSupportCases,
      priority,
    };
  },
};
</script>
