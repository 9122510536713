import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from '@/router'
export default function useSupportCasesList() {
  // Use toast
  const toast = useToast();
  const refSupportCaseListTable = ref(null);
  // Table Handlers
  const tableColumns = [
    { key: "id", sortable: true },
    { key: "summary", sortable: true },
    { key: "reporterEmail", sortable: false },
    { key: "status", sortable: true },
    { key: "priority", sortable: true },
  ];
  const perPage = ref(10);
  const totalSupportCases = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const statusSelect = ref(null);
  const prioritySelect = ref(null);
  const organziationSelect = ref(null);
  const userSelect = ref(null);
  const isSearch = ref(false);
  const activeAddSupportCase = ref(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const dataMeta = computed(() => {
    const localItemsCount = refSupportCaseListTable.value
      ? refSupportCaseListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSupportCases.value,
    };
  });
  const refetchData = () => {
    refSupportCaseListTable.value.refresh();
  };
  watch(
    [
      currentPage,
      perPage,
      isSearch,
      roleFilter,
      planFilter,
      statusFilter,
      statusSelect,
      prioritySelect,
      organziationSelect,
      userSelect,
    ],
    () => {
      refetchData();
    }
  );

  const fetchSupportCases = (ctx, callback) => {
    userData = JSON.parse(localStorage.getItem("userData"));
    store.commit("appConfig/SPINNER_CONFIG", true);
    const organizationData = {
      currentPage: currentPage.value,
      perPage: perPage.value,
      sortBy: sortBy.value,
      sortOrder: isSortDirDesc.value,
      region: userData.default_region_id,
    };

    if((userData.role_name == 'ACCOUNT_MANAGER'|| userData.role_name == 'GLOBAL_ADMIN') && userData.orgs_list){
      if(userData.orgs_list.length > 0){
      organizationData.company_id = userData.orgs_list[0];
      }
      else{
        organizationData.company_id = userData.company_id;
      }

    }

    if(userData.role_name !== 'ACCOUNT_MANAGER' && userData.role_name !== 'GLOBAL_ADMIN'){
      organizationData.company_id = userData.company_id;
    }

    if (searchQuery.value !== "") {
      organizationData.searchQuery = searchQuery.value;
    }

    if (statusSelect.value !== null) {
      organizationData.status = statusSelect.value.name;
    }

    if (prioritySelect.value !== null) {
      organizationData.priority = prioritySelect.value.name;
    }

    if (organziationSelect.value !== null) {
      organizationData.company_id = organziationSelect.value.id;
    }

    if (userSelect.value !== null) {
      organizationData.reporter = userSelect.value.email;
    }

    store
      .dispatch("app-support-cases/fetchSupportCases", organizationData)
      .then((response) => {
        if (response.data) {
          const { issues, total } = response.data;
          callback(issues, total);
          isSearch.value = false;
          store.commit("appConfig/SPINNER_CONFIG", false);
          totalSupportCases.value = total;
          if(router.currentRoute.name == 'apps-support-cases-open-ticket'){
            setTimeout(() => {activeAddSupportCase.value = true;},4000)
          }
        }
      })
      .catch(() => {
        callback([], 0);
        isSearch.value = false;
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching supportcases list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveSupportCaseRoleVariant = () => {
    return "primary";
  };
  return {
    fetchSupportCases,
    tableColumns,
    perPage,
    currentPage,
    totalSupportCases,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSupportCaseListTable,
    isSearch,
    statusSelect,
    prioritySelect,
    organziationSelect,
    userSelect,
    activeAddSupportCase,
    resolveSupportCaseRoleVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}