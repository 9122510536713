import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSupportCases(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/servicedesk/issues/organization/id/${data.company_id}`,
            {
              params: {
                page: data.currentPage, per_page: data.perPage,
                sort_by: data.sortBy, sort_order: data.sortOrder ? 'desc' : 'asc',
                status: data.status, priority: data.priority, reporter: data.reporter, search: data.searchQuery, region_id: data.region,
              },
            }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSupportCasesSearchQuery(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/servicedesk/issue/search`,
            {
              params: {
                query: data.searchQuery, per_page: data.perPage,
                sort_by: data.sortBy, sort_order: data.sortOrder ? 'desc' :'asc'
              },
            }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRequestTypes(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicedesk/requesttype`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicedesk/status`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPriority(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicedesk/priority`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganization(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/organizations/region/${data.region_id}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOrganizationByAccountManager(ctx, data){
      return new Promise((resolve, reject) => {
        axios
          .get(`/organizations/account_manager/${data.id}`,{
          params: {
            region_id: data.region_id
          },
        }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSupportCase(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicedesk/request/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSupportCases(ctx, supportcaseData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/servicedesk/request", supportcaseData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCommentByIssueId(ctx, commentData) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/servicedesk/request/${commentData.issueId}/comment`,
            commentData
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSupportCase(ctx, supportcaseData) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            `/servicedesk/request/${supportcaseData.issueId}`,
            supportcaseData
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    deleteSupportCase(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicedesk/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
