<template>
  <div class="align">
    <b-card>
      <!-- SupportCase Info: Input Fields -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-1">
            <!-- Field: First Name -->
            <b-col cols="12" md="4">
              <validation-provider
                #default="validationContext"
                name="summary"
                rules="required"
              >
                <b-form-group label="Summary" label-for="summary">
                  <b-form-input
                    id="summary"
                    v-model="supportCaseData.summary.value"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}</b-form-invalid-feedback
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->

            <b-col cols="12" md="4">
              <b-form-group label="Priority" label-for="priority">
                <v-select
                  id="priority"
                  v-model="supportCaseData.priority.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="priority.response"
                  :clearable="false"
                  label="name"
                  :reduce="(val) => val.id"
                  class="per-page-selector d-inline-block mx-50"
                >
                  <span slot="no-options">{{ priority.message }}</span>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="12">
              <div class="mt-2">
                <vue-editor
                  id="comment-editor"
                  placeholder="Description is required"
                  useCustomImageHandler
                  @image-added="handleImageAdded"
                  v-model="supportCaseData.description.value"
                  :editor-toolbar="customToolbar"
                  :class="
                    supportCaseData.description.value == ''
                      ? 'editor-border-danger'
                      : 'editor-border'
                  "
                />
              </div>
            </b-col>
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mt-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="supportCaseData.description.value == ''"
            type="submit"
          >
            <div class="align-items-center">
              <b-spinner
                small
                label="Text Centered"
                v-if="isLoading"
              ></b-spinner>
              <span class="button-title">Save Changes</span>
            </div>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card>
      <b-card-text>
        <b-row class="d-flex justify-content-between attachement-align">
          <b-col lg="2" md="3" sm="4" class="d-flex justify-content-start">
            <h5>Attachments</h5>
          </b-col>
          <b-col lg="3" md="3" sm="4" class="d-flex justify-content-end">
            <b-button @click="$refs.file.click()" variant="primary">
              <input
                ref="file"
                type="file"
                class="d-none"
                @input="submitFile"
              />
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  label="Text Centered"
                  v-if="isLoad"
                ></b-spinner>
                <span class="button-title">Add Attachment</span>
              </div>
            </b-button>
          </b-col>
        </b-row>

        <div class="grid">
          <b-card
            id="attachment"
            border-variant="info"
            v-for="(file, i) in attachements"
            :key="i"
          >
            <span @click="viewAttachment(file.file_id)">
              <img
                :src="file.thmubnail_url"
                class="cursor-pointer"
                alt="attachmentimg"
              />
            </span>
            <b-card-text class="file-text">
              <span>
                {{
                  file.file_name.length > 20
                    ? file.file_name.substring(0, 15) + "..."
                    : file.file_name
                }}
              </span>
              <feather-icon
                icon="Trash2Icon"
                @click="deleteFile(file)"
                class="cursor-pointer feather-danger"
            /></b-card-text>
          </b-card>
        </div>
      </b-card-text>
    </b-card>

    <b-card>
      <h5>Comments</h5>
      <div class="mt-2">
        <b-card-text>
          <!-- comment box -->
          <div>
            <b-form-group v-if="!isComment">
              <b-form-textarea
                @click="
                  isComment = true;
                  isCommentEdit = false;
                  editCommentId = '';
                "
                rows="2"
                placeholder="Add Comment"
              />
            </b-form-group>
            <vue-editor
              v-if="isComment"
              id="comment-editor"
              useCustomImageHandler
              @image-added="handleImageAdded"
              v-model="commentContent"
              :editor-toolbar="customToolbar"
            >
            </vue-editor>
          </div>
          <b-button
            v-if="isComment"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="mt-1"
            @click="onCommentSubmit"
            :disabled="commentContent == ''"
          >
            <div class="d-flex align-items-center">
              <b-spinner small label="Text Centered" v-if="Loading"></b-spinner>
              <span class="button-title">Post Comment</span>
            </div>
          </b-button>
          <b-button
            v-if="isComment"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            class="mt-1 ml-1"
            @click="cancel"
          >
            Cancel
          </b-button>

          <div
            v-for="(comment, ind) in supportCaseData.detailedComments"
            :key="ind"
            class="d-flex align-items-start mb-1 mt-1"
          >
            <b-avatar :src="comment.avatar" size="34" class="mt-25 mr-75" />
            <div class="profile-user-info w-100">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">
                  {{ comment.displayName }}
                </h6>
                <span
                  :title="
                    moment(comment.created).format('Do MMMM YYYY [at] h:mm')
                  "
                  class="ml-1 cursor-pointer"
                  @click="showTime ? (showTime = false) : (showTime = true)"
                  >{{
                    showTime
                      ? moment(comment.created).format("Do MMMM YYYY [at] h:mm")
                      : moment(comment.created).fromNow()
                  }}</span
                >
              </div>
              <small
                id="comment-img"
                v-if="editCommentId !== comment.id"
                v-html="comment.html"
                class="comment"
                >{{
              }}</small>

              <vue-editor
                v-if="isCommentEdit && editCommentId === comment.id"
                id="comment-editor"
                v-model="comment.comment"
                useCustomImageHandler
                @image-added="handleImageAdded"
                :editor-toolbar="customToolbar"
              ></vue-editor>
              <b-button
                v-if="isCommentEdit && editCommentId === comment.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                class="mt-1"
                @click="
                  isCommentEdit = false;
                  editCommentId = '';
                "
              >
                Save
              </b-button>
              <b-button
                v-if="isCommentEdit && editCommentId === comment.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                class="mt-1 ml-1"
                @click="
                  isCommentEdit = false;
                  editCommentId = '';
                "
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-card-text>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
  BCardImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import moment from "moment";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useSupportCasesList from "../support-cases-list/useSupportCasesList";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import router from "@/router";
import { VueEditor } from "vue2-editor";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    // editor: Editor,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BSpinner,
    BCardImg,
    VueEditor,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    supportCaseData: {
      type: Object,
      required: true,
    },
    priority: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      showTime: false,
      moment: moment,
      isComment: false,
      isCommentEdit: false,
      editCommentId: "",
      commentContent: "",
      images: null,
      isLoad: false,
      Loading: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic"],
        [
          { align: "" },
          { align: "center" },
          { align: "justify" },
          { align: "right" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["image", "link", "video"],
        ["clean"],
      ],
    };
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let reader = new FileReader();
      reader.onloadend = function () {
        Editor.insertEmbed(cursorLocation, "image", reader.result);
        Editor.formatText(cursorLocation, cursorLocation + 1, "alt", file.name);
        resetUploader();
      };
      reader.readAsDataURL(file);
    },
    viewAttachment(id) {
      const config = { responseType: "blob" };
      axios
        .get(`/servicedesk/attachment/content/${id}`, config)
        .then(function (res) {
          let url = URL.createObjectURL(res.data);
          window.open(url);
        })

        .catch(function (err) {
          console.log(err);
        });
    },
    editComment(comment) {
      this.editCommentId = comment.id;
      this.isCommentEdit = true;
    },
    cancel() {
      this.isComment = false;
      this.commentContent = "";
    },
    onCommentSubmit() {
      let data = {
        issueId: this.supportCaseData.issueId,
        comment: this.commentContent,
      };
      this.Loading = true;
      store
        .dispatch("app-support-case/addCommentByIssueId", data)
        .then(() => {
          this.Loading = false;
          this.$emit("refetch-data");
          this.isComment = false;
          this.commentContent = "";
        })
        .catch(() => {
          this.Loading = false;
          this.isComment = false;
          this.commentContent = "";
        });
    },
    submitFile() {
      this.isLoad = true;
      const formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      const headers = {};
      axios
        .post(
          "/servicedesk/request/" +
            this.supportCaseData.issueId +
            "/attachment",
          formData,
          { headers }
        )
        .then((res) => {
          this.fetchthumbnails(res.data[0]);

          this.isLoad = false;
          this.onAttachement();
          this.$emit("refetch-data");

          res.data.files; // binary representation of the file
          res.status; // HTTP status
        })
        .catch(() => {
          this.isLoad = false;
        });
    },
    deleteFile(file) {
      axios
        .delete("/servicedesk/attachment/" + file.file_id)
        .then((res) => {
          this.$emit("refetch-data");
          this.deletethumbnails(file.file_id);
        })
        .catch(() => {});
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    let isLoading = ref(false);

    const { resolveSupportCaseRoleVariant } = useSupportCasesList();

    let attachements = ref([]);
    const fetchthumbnails = (file) => {
      const config = { responseType: "blob" };
      axios
        .get(`/servicedesk/attachment/thumbnail/${file.id}`, config)
        .then(function (res) {
          let url = URL.createObjectURL(res.data);
          attachements.value.push({
            file_id: file.id,
            file_name: file.filename,
            thmubnail_url: url,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    };
    const deletethumbnails = (id) => {
      const config = { responseType: "blob" };
      axios
        .get(`/servicedesk/attachment/thumbnail/${id}`, config)
        .then(function (res) {
          for (const [key, value] of Object.entries(attachements.value)) {
            if (value.file_id === id) {
              attachements.value.splice(key, 1);
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    };
    let files = props.supportCaseData?.attachment?.value;
    for (let key in files) {
      const config = { responseType: "blob" };
      axios
        .get(`/servicedesk/attachment/thumbnail/${files[key].id}`, config)
        .then(function (res) {
          let url = URL.createObjectURL(res.data);
          attachements.value.push({
            file_id: files[key].id,
            file_name: files[key].filename,
            thmubnail_url: url,
          });
        })
        .catch(function (err) {
          console.log(err);
        });
    }

    const onSubmit = () => {
      let supportCaseInfo = {
        summary: props.supportCaseData.summary.value,
        issueId: props.supportCaseData.issueId,
        description: props.supportCaseData.description.value,
        priority_id: props.supportCaseData.priority.id,
      };
      isLoading.value = true;
      store
        .dispatch("app-support-case/updateSupportCase", supportCaseInfo)
        .then(() => {
          isLoading.value = false;
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success `,
              icon: "CoffeeIcon",
              variant: "success",
              text: `SupportCase updated successfully`,
            },
          });
          router.push("/apps/support-cases/list"); // redirect to the feed
        })
        .catch(() => {
          isLoading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `SupportCase can not be update`,
            },
          });
        });
    };

    const { refFormObserver, getValidationState } = formValidation();

    return {
      isLoading,
      resolveSupportCaseRoleVariant,
      avatarText,

      onSubmit,
      attachements,
      fetchthumbnails,
      deletethumbnails,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";
@import "@core/scss/vue/libs/vue-select.scss";

@import "../supportCaseStyle.scss";
#comment-editor > div.ql-editor > p > img,
#comment-img > img {
  max-width: 450px;
  display: flex;
  margin-bottom: 20px;
}

.editor-border {
  border: 1px solid $custom-control-border-color;
}
.editor-border-danger {
  border: 1px solid $required;
}
#app
  > div.horizontal-layout.horizontal-menu.navbar-floating.footer-static
  > div.app-content.content
  > div.content-wrapper
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > p
  > div.row.justify-content-between.mb-2
  > div.col-lg-2
  > h5 {
  margin-top: 0.5rem !important;
}
#app
  > div.horizontal-layout.horizontal-menu.navbar-floating.footer-static
  > div.app-content.content
  > div.content-wrapper
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > p
  > div.row.justify-content-between.mb-2
  > div.col-lg-3
  > button {
  margin-top: 0 !important;
}
.align {
  padding: 0 !important;
}
.attachement-align {
  margin-bottom: 0.5rem;
}
.align {
  padding: 0 !important;
}
.file-text {
  flex-direction: row !important;
  align-items: center;
}
</style>
